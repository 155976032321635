body {
	font-family: 'Fira Sans', sans-serif;
	color: white;
	background: black;
}

a,
.link {
	color: #e66;
	text-decoration: underline;
	cursor: pointer;
}

a:hover,
.link:hover {
	color: #ffcaca;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

::-webkit-scrollbar {
	width: 10px;
	background: black;
}

::-webkit-scrollbar-thumb {
	background: gray;
}

* {
	scrollbar-color: gray black;
	scrollbar-width: thin;
}

#no-webgl {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	margin-top: 20px;
	display: none;
}

#canvas {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: opacity 1s;
	opacity: 0;
}

#canvas-holder {
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

#loader {
	background: black;
	cursor: default;
	color: white;
	position: absolute;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	width: 250px;
	height: 50px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	text-align: center;
}

#loader-text {
	display: inline-block;
	vertical-align: text-bottom;
	line-height: 50px;
}

#spinner {
	display: inline-block;
	height: 50px;
	width: 50px;
	position: relative;
}

#sat-hoverbox {
	background: black;
	cursor: default;
	color: white;
	position: absolute;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	pointer-events: none;
}

#all-objects-link {
	font-size: 12px;

	margin: 5px 0;
}

#sat-info-title {
	text-align: center;
	margin-bottom: 5px;
	font-size: 18px;
}

.sat-info-row {
	text-align: center;
	margin-bottom: 5px;
	height: 18px;
}

.sat-info-key,
.sat-info-value {
	display: inline-block;
	height: 18px;
}

.sat-info-key {
	font-size: 14px;
	color: #aaa;
	float: left;
}

.sat-info-value {
	float: right;
}

#search-results {
	width: 300px;
	padding: 0;
}

.search-hilight {
	font-weight: bold;
	color: #faa;
}

.search-result {
	padding: 5px;
	height: 28px;
	line-height: 28px;
}

.search-result:hover {
	background: #c33;
	cursor: pointer;
}

.search-result-intldes {
	font-size: 12px;
	height: 28px;
	float: right;
	margin-right: 10 px;
}

#search {
	background: rgba(0, 0, 0, 0);
	border: none;
	border-bottom: 1px solid white;
	color: white;
	font-family: 'Droid Sans', sans-serif;
	font-size: 16px;
	outline: none;
}

.menubar {
	height: 30px;
	position: absolute;
	top: 5px;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	align-content: stretch;
}

#menu-left {
	left: 0px;
	justify-content: flex-start;
}

#menu-right {
	right: 0px;
	justify-content: flex-end;
}

.menu-item {
	cursor: pointer;
	margin: 0 10px;
	z-index: 11;
}

.menu-item a {
	color: inherit;
	text-decoration: none;
}

.menubox {
	cursor: default;
}

.menu-item:first-child {
	margin-left: 2px;
}

.menu-item:hover .menu-title {
	color: #faa;
}

.menu-title {
	height: 30px;
}

.menu-title.active {
	font-weight: 500;
}

.submenu,
.submenu__new {
	background: black;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	margin: 0;
	overflow: hidden;
	position: absolute;
}

.submenu {
	display: none;
}

.dropdown {
	width: 200px;
}

.menubox {
	padding: 10px;
}

.dropdown > li {
	display: block;
	padding: 5px 10px;
}

.dropdown > li:hover {
	background: #c33;
	font-weight: bold;
}

.dropdown > li:active {
	background: #511;
}

.dropdown > li.clear-option {
	background: #511;
}

#zoom-controls {
	position: absolute;
	right: 10px;
	top: 40px;
	width: 40px;
	height: 70px;
	background: black;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	overflow: hidden;
}

.zoom-button {
	height: 35px;
	font-size: 20px;
	font-weight: bold;
	line-height: 35px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.zoom-button:hover {
	background: #c33;
}

.zoom-button:active {
	background: #511;
}

#zoom-in {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#zoom-out {
}

#about-box {
	right: 10px;
	width: 500px;

	a {
		text-decoration: underline;
	}

	img {
		margin: 8px 16px 8px 0;
		float: right;
	}
}

#help-box {
	width: 275px;
}

.box-header {
	font-size: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

#legend {
	margin-bottom: 25px;
}

#legend li {
	margin: 5px 0;
}

.dot {
	vertical-align: middle;
	margin-right: 8px;
	display: inline-block;
	width: 0;
	border-width: 4px;
	border-style: solid;
	border-radius: 100%;
	filter: blur(1.5px);
}

.dot.red {
	border-color: #ff3300;
}

.dot.green {
	border-color: #33ff00;
}

.dot.yellow {
	border-color: #ffff33;
}

.dot.cyan {
	border-color: #00ffff;
}

.dot.purple {
	border-color: #c088ff;
}

#controls-info {
	margin-top: 10px;
}

#controls-info > li {
	margin-top: 10px;
}

.left-panel {
	display: none;
	position: absolute;
	transition: transform 0.2s;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-width: 0 1px 0 0;
	color: white;
	background: rgba(0, 0, 0, 0.8);
	z-index: 10;
	top: 40px;
	bottom: 0;
	overflow: auto;
	height: 100%;
	padding: 16px;

	.box-header {
		margin: 0 0 24px 0;
	}

	&.left-transition--enter {
		display: block;
		transform: translateX(-100%);
	}

	&.left-transition--enter-active {
		transform: translateX(0);
	}

	&.left-transition--enter-done {
		display: block;
	}

	&.left-transition--exit {
		display: block;
		transform: translateX(0);
	}

	&.left-transition--exit-active {
		transform: translateX(-100%);
	}
}

.legend-box {
	min-width: 275px;

	.controls-info {
		color: #aaaaaa;

		li {
			margin: 8px 0;
		}
	}
}

.references-box {
	min-width: 275px;

	ul {
		max-width: 600px;
	}

	li {
		margin: 8px 0;
	}
}

.construction-banner {
	position: absolute;
	top: 17.5%;
	left: 50%;
	transform: translateX(-50%);
	background: black;
	border-radius: 5px;
	padding: 8px;
	color: gray;
}
