$label-padding: 1rem;

.infoBox {
	background: rgba(0, 0, 0, 0.9);
	cursor: default;
	color: #e9e9e9;
	position: absolute;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	right: 0;
	//top: 130px;
	bottom: 20px;
	margin-top: auto;
	margin-bottom: auto;
	width: 550px;
	max-height: 75%;
	overflow: auto;
}

.header {
	font-weight: 500;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

.header:first-child {
	margin-top: 0;
}

.label,
.data {
	display: inline-block;
}

.label {
	color: gray;
	width: calc(40% - #{$label-padding});
	padding-left: $label-padding;
}

.data {
	color: lightgray;
	text-align: right;
	width: 60%;
}

.heritageLabel {
	display: block;
	color: gray;
	margin: 16px 0 8px;
}

.heritageData {
	width: calc(100% - #{$label-padding});
	margin: 8px 0;
	padding-left: $label-padding;
}

.link {
	color: darkgray;
	text-decoration-color: gray;
}
